<template>

    <div class="section-content">
        <div class="primary-section-content">
            <div class="main-section-heading">
                <div>
                    <h1 class="main-section-header">Manage Space Types</h1>
                    <br />
                    <br />
                    <p>Configure what attributes define a space type.<br /></p>
                </div>
            </div>

            <div class="content-view">

                <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>



                <button class="w-button"
                    :class="[view == 'storage' ? 'primary-btn' : 'tertiary-btn']" @click="view = 'storage'" v-if="unit_types.length > 1 && unit_types.indexOf('storage') > 0">
                    Storage
                </button>
                <button class="w-button" :class="[view == 'residential' ? 'primary-btn' : 'tertiary-btn']" @click="view = 'residential'" v-if="unit_types.length > 1 && unit_types.indexOf('residential') > 0">
                    Residential
                </button>
                <button class="w-button" :class="[view == 'parking' ? 'primary-btn' : 'tertiary-btn']" @click="view = 'parking'" v-if="unit_types.length > 1 && unit_types.indexOf('parking') > 0">
                    Residential
                </button>

                <div class="table">


                    <div class="table-row">
                        <div class="table-head" style="flex-basis: 60%">
                            Space Type
                        </div>
                        <div class="table-head">
                            No. Spaces
                        </div>
                        <div class="table-head text-right" style="flex-basis: 20%">
                            Price
                        </div>
                    </div>

                    <div class="table-row" v-for="type in space_types"  :key="type.id">
                        <div class="table-cell" style="flex-basis: 60%">
                            <strong>{{type.width}} X {{type.length}}</strong>
                        </div>
                        <div class="table-cell">
                            <strong>{{type.count}}</strong><br />
                        </div>
                        <div class="table-cell text-right" style="flex-basis: 20%">
                            {{type.price | formatMoney}}
                        </div>
                    </div>
                </div>



<!--                {{ features }}-->
                <dropdown
                    :options="features"
                    labelField="name"
                    v-model="newParam"
                >
                </dropdown>


                <button class="w-button primary-btn" @click="addParam">Add Parameter</button>

            </div>

        </div>


    </div>
</template>

<script type="text/babel">
	import Modal from '../assets/Modal.vue';
	import Dropdown from '../assets/Dropdown.vue';
	import api from '../../assets/api.js';


	export default {
		name: "SpaceTypeSettings",
		data() {
			return {
				unit_types: [],
                view: '',
				features:[],
				newParam: {},
				space_types:[],
				space_type_parameters: []
			}
		},
		components:{
			Modal,
			Dropdown,

		},
		async created(){
			await this.fetchUnitTypes();
			await this.fetchAmenities();
			await this.fetchSpaceTypes();
		},
		computed:{},
		methods:{

			async addParam(){
				let parameter = await api.post(this, api.SETTINGS + 'space-type-parameter', {id: this.newParam.id });
                await this.fetchData();
            },

			async removeParam(){

                await api.delete(this, api.SETTINGS + 'space-type-parameter/' + selected.id);
                await this.fetchData();
			},


			async fetchUnitTypes(){
				let response = await api.get(this, api.UNITS + 'unit-types');
				this.unit_types = response.types.map(data => data.type );
                this.view = this.unit_types[0];
			},

			closeWindow(){
                this.selected = {};
			},

			async fetchSpaceTypes(){
				let results = await api.get(this, api.SETTINGS + 'space-types/' + this.view);
				this.space_types = results.space_types;
				this.space_type_parameters = results.space_type_parameters;
            },
			async fetchAmenities(){
				let response = await api.get(this, api.UNITS + 'features/' + this.view);
				this.features = response.features;
			},


			saveNewOrder(){
				var data = {categories: this.categories};
				api.put(this, api.CATEGORIES_SORT, data).then(r => {

				});


			},
			editItem(c){
				this.selected = c;
				this.showEdit = true;
			},
			deleteItem(c){
				this.selected = c;
				this.showDelete = true;
			},
			deleteConfirm(){

				api.delete(this, api.CATEGORIES, this.selected.id).then(r => {
					this.showDelete = false;
					this.fetchData();
					this.selected = {};
				});

			},
		}
	}
</script>

<style>
    .subdued {
        color: #a5adb4;
        line-height: 18px;
    }
</style>